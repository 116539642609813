.paper-tile {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fbfbfb;
}

.paper {
    font-size: smaller;
}

.display_none {
    display: none;
}

.topic-tag {
    margin-left: 0;
    border: none;
    border-radius: 0;
}
