.label-connector {
    margin-right: 10px;
    margin-left: 10px;
    vertical-align: middle;
}

.no-bottom-border {
    border-bottom: none;
}

.whats-next > .bx--accordion__content {
    padding: 10px;
}

.whats-next .bx--structured-list {
    margin-bottom: 10px;
}

.selected-circle circle {
    fill: blue;
}

.unselected-circle circle {
    fill: silver;
}

.special-circle circle {
    fill: green;
}
