.bx--inline-notification__subtitle,
.bx--toast-notification__subtitle {
    margin-top: 10px;
}

.fake-node {
    background-color: #e4e4e4 !important;
    fill: #e4e4e4 !important;
}

.bx--tag {
    margin-left: 0;
}
