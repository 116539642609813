@use '@carbon/react';
@use '@carbon/react/scss/breakpoint' as *;

/// Remove overrides once Carbon bugs are fixed upstream.
/// Need grid option to not add page gutters at large viewports, to also use when nesting grids
/// @link https://github.com/carbon-design-system/carbon/issues/2792
@media (min-width: 42rem) {
    .cds--grid--no-gutter {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/// Padding was introduced in 10.3.0, needs an option to let grid set the viewport gutter
/// @link https://github.com/carbon-design-system/carbon/issues/3010
.cds--content {
    margin-top: 0.5rem;
    padding: 0;
    background: var(--cds-background);
}

.cds--content .cds--css-grid {
    max-width: 100%;
    @include breakpoint(md) {
        padding-left: 0px;
        padding-right: 0px;
    }
}
