.text-blue {
    color: #0f62fe;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.cds--container {
    padding: 20px;
}

.footer {
    position: fixed;
    bottom: 0;
    font-size: smaller;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;
}

.bonus-link {
    box-shadow: none !important;
    width: fit-content !important;
}

.bonus-link path {
    fill: #0f62fe !important;
}

.buttonset {
    box-shadow: none !important;
}

.tertiary-danger {
    border-color: red;
    color: red;
}

.tertiary-danger:hover {
    background-color: red;
}

.tertiary-secondary {
    border-color: black;
    color: black;
}

.tertiary-secondary:hover {
    background-color: black;
}

.see-also .cds--contained-list__header,
.see-also .cds--contained-list-item__content {
}
